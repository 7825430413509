import React from 'react';
import styled from 'styled-components';

import {ContainerLg, ExternalLinkButton} from '../../components';
import {StaticImage} from 'gatsby-plugin-image';
import {breakpointsMediaQueries, COLORS} from '../../../style/my-theme';
import {SponsorInfo} from '../../content';

const StyledWorkshopSponsorsSection = styled.section.attrs({
  className: 'sponsors-overview-section'
})`
  margin: 4rem 0;

  .container-lg {
    .section-description {
      line-height: 2rem;
      text-align: center;
      font-family: 'Montserrat', sans-serif;

      h1 {
        color: ${COLORS.primary};
        font-size: 2rem;
        font-weight: 600;
        &.gold {
          font-size: 3rem;
          line-height: 3rem;

          ${breakpointsMediaQueries.md} {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }
        }
        &.silver {
          font-size: 2rem;
        }
        &.friends {
          font-size: 1.5rem;
        }
      }
      h2 {
        color: ${COLORS.primary};
        font-size: 1.6rem;
        a {
          color: ${COLORS.blueLight};
        }
      }
    }
    .gold-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 2rem;

      a {
        min-width: 300px;
        text-align: center;
      }

      ${breakpointsMediaQueries.md} {
        display: flex;
        flex-direction: column;

        a {
          text-align: center;
          img {
            max-width: 80%;
            object-fit: contain;
          }
        }
      }
    }
  }
`;

const WorkshopSponsorsSection = () => {
  return (
    <StyledWorkshopSponsorsSection>
      <ContainerLg>
        <div className="section-description">
          <h1 className="gold">SPONSORED BY</h1>
        </div>
        <div className="gold-section">
          <a href={'https://www.comdata.rs/'} target="_blank">
            <img src="/images/sponsors-new/comdata-logo.png" alt={'ComData logo'} height={224} />
          </a>
        </div>

        <div className="section-description">
          <h2>WANT TO JOIN AS A SPONSOR?</h2>
          <ExternalLinkButton
            label="CONTACT US!"
            url="mailto:contact@armada-js.com"
          ></ExternalLinkButton>
        </div>
      </ContainerLg>
    </StyledWorkshopSponsorsSection>
  );
};

export default WorkshopSponsorsSection;
